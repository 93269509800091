html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: 100% inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.MuiListItem-root.bold .MuiListItemText-root p {
  font-family: 'Roboto-bold';
}

b {
  font-family: 'Roboto-bold';
}

i {
  font-family: 'Roboto-italic';
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(@/Assets/fonts/Roboto-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(@/Assets/fonts/Roboto-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(@/Assets/fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url(@/Assets/fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-italic';
  src: url(@/Assets/fonts/Roboto-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-BI';
  src: url(@/Assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(@/Assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(@/Assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(@/Assets/fonts/Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(@/Assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(@/Assets/fonts/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(@/Assets/fonts/Roboto-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(@/Assets/fonts/Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(@/Assets/fonts/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
  font-family: 'Roboto';
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: none;
}


#root {
  height: 100vh;
}

.react-datepicker-wrapper {
  width: auto !important;
  margin-right: 20px;
}

.react-datepicker-wrapper input {
  width: 120px;
  border: 1px solid #0277bd;
  border-radius: 20px;
  padding: 5px 10px;
  font-family: Roboto;
  margin-top: 1px;
}

.react-datepicker-wrapper input::placeholder {
  color: rgba(0,0,0,0.87);
  opacity: 0.42;
}

.react-datepicker__tab-loop {
  display: inline-block;
}

/**********************************************************************
    Flexbox Styles
***********************************************************************/
.flex-col,
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row,
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-ss,
.flex-row-ss {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row-es,
.flex-row-es {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-row-sc,
.flex-row-sc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-sb,
.flex-row-sb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.flex-row-se,
.flex-row-se {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-row-be,
.flex-row-be {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-row-ec,
.flex-row-ec {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-row-eb,
.flex-row-eb {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.flex-row-cc,
.flex-row-cc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-cb,
.flex-row-cb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.flex-col-cc,
.flex-col-cc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-ss,
.flex-col-ss {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-col-sc,
.flex-col-sc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-col-cs,
.flex-col-cs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-col-ce,
.flex-col-ce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flex-col-se,
.flex-col-se {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-wrap,
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap,
.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-start,
.justify-start {
  justify-content: flex-start;
}

.justify-end,
.justify-end {
  justify-content: flex-end;
}

.justify-center,
.justify-center {
  justify-content: center;
}

.justify-between,
.justify-between {
  justify-content: space-between;
}

.justify-around,
.justify-around {
  justify-content: space-around;
}

.justify-evenly,
.justify-evenly {
  justify-content: space-evenly;
}

.items-start,
.items-start {
  align-items: flex-start;
}

.items-end,
.items-end {
  align-items: flex-end;
}

.items-center,
.items-center {
  align-items: center;
}

.items-stretch,
.items-stretch {
  align-items: stretch;
}

.items-base,
.items-base {
  align-items: baseline;
}

.content-start,
.content-start {
  align-content: flex-start;
}

.content-end,
.content-end {
  align-content: flex-end;
}

.content-center,
.content-center {
  align-content: center;
}

.content-stretch,
.content-stretch {
  align-content: stretch;
}

.content-between,
.content-between {
  align-content: space-between;
}

.content-around,
.content-around {
  align-content: space-around;
}

.flex,
.flex,
.flex-1,
.flex-1 {
  flex-basis: 0;
  flex-grow: 1;
}

.flex-2,
.flex-2 {
  flex-basis: 0;
  flex-grow: 2;
}

.flex-3,
.flex-3 {
  flex-basis: 0;
  flex-grow: 3;
}

.flex-4,
.flex-4 {
  flex-basis: 0;
  flex-grow: 4;
}

.flex-5,
.flex-5 {
  flex-basis: 0;
  flex-grow: 5;
}

.flex-6,
.flex-6 {
  flex-basis: 0;
  flex-grow: 6;
}

.flex-7,
.flex-7 {
  flex-basis: 0;
  flex-grow: 7;
}

.flex-8,
.flex-8 {
  flex-basis: 0;
  flex-grow: 8;
}

.flex-9,
.flex-9 {
  flex-basis: 0;
  flex-grow: 9;
}

.flex-10,
.flex-10 {
  flex-basis: 0;
  flex-grow: 10;
}

.flex-11,
.flex-11 {
  flex-basis: 0;
  flex-grow: 11;
}

.flex-12,
.flex-12 {
  flex-basis: 0;
  flex-grow: 12;
}
/**********************************************************************
    Dev Styles
***********************************************************************/
.debug {
  border: 1px solid #ff0000;
}

.debug2 {
  border: 1px solid #ffff00;
}

.debug3 {
  border: 1px solid green;
}

.debug4 {
  border: 1px solid blue;
}
.SnackbarItem-message{
  font-size: 14px;
  font-size: 1.4rem;
}